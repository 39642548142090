import { template as template_509bbb25ea2e4153adeceaccbee6d2be } from "@ember/template-compiler";
const SidebarSectionMessage = template_509bbb25ea2e4153adeceaccbee6d2be(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
