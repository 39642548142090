import { template as template_393d88c242434b20ae51ae44891c15e3 } from "@ember/template-compiler";
const FKLabel = template_393d88c242434b20ae51ae44891c15e3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
