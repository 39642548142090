import { template as template_b271d878089d4b858ceb154a284cb569 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b271d878089d4b858ceb154a284cb569(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
