import { template as template_70f0256c9cfd48049c4a0a66ed700929 } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import FKCol from "discourse/form-kit/components/fk/col";
const FKRow = template_70f0256c9cfd48049c4a0a66ed700929(`
  <div class="form-kit__row" ...attributes>
    {{yield (hash Col=FKCol)}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKRow;
